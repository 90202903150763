<template>
  <div class="h-full">
    <div class="suporte-cliente-tarefas">
      <div class="sheader">
        <h2 class="component-title">Fila de Atendimento</h2>
        <div class="btns">
          <u-btn class="sl-btn box-v2 m-l" color="green" no-caps @click="open">
            <i class="fa-duotone fa-user-headset" /> <span>Nova Solicitação de Suporte</span>
          </u-btn>
        </div>
      </div>

      <div class="cliente-tarefas">
        <task-list-page ref="list" :autoload="true" is-gerencia />
      </div>
    </div>
  </div>
</template>

<script>

import TaskListPage from "components/suporte/cliente/components/TarefasList.vue"
import criarTarefa from "components/suporte/cliente/window/criarTarefa"

export default {
  name: "ClienteTarefas",
  data () {
    return {
      response: {},
    }
  },
  components: {
    TaskListPage
  },
  mounted() {
  },
  methods: {
    criarTarefa: criarTarefa,
    open () {
      this.criarTarefa()
    }
  }
}
</script>
